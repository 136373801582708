<template>
    <div class="input-group">
        <b-input-group append="cm">
            <b-form-input
                class="form-control"
                type="number"
                :disabled="!editable"
                :placeholder="$t('horse.taille')"
                v-model="horse_taille"
                @keyup="$emit('update:horse_prop_taille', horse_taille)"
            />
        </b-input-group>
    </div>
</template>

<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js";

	export default {
		mixins: [HorseMixin],
		props: [
            'horse_id',
            'horse_prop_taille',
            'editable'
		],
		data () {
			return {
                horse_taille: this.horse_prop_taille
			}
		},
        watch: {
            horse_prop_taille(val) {
                this.horse_taille = val
            }
        }
	}
</script>
